<template>
  <vp-base-layout
    page-title="내역서 다운로드"
    page-default-back-link="/">
    <ion-list lines="full">
      <ion-list-header>
        <ion-label>다운로드 받으시려는 년월을 선택 후 <br /> 발송버튼을 눌러주세요.</ion-label>
      </ion-list-header>
    
      <ion-item>
        <ion-select
          aria-label="Year"
          interface="popover"
          v-model="searchParams.year">
          <template
            v-for="(item, index) in year"
            :key="index">
            <ion-select-option :value="item">
              {{ item }} 년
            </ion-select-option>
          </template>
        </ion-select>
        <ion-select
          aria-label="Month"
          interface="popover"
          v-model="searchParams.month">
          <template
            v-for="(item, index) in month"
            :key="index">
            <ion-select-option :value="item">
              {{ item }} 월
            </ion-select-option>
          </template>
        </ion-select>
      </ion-item>
    </ion-list>

    <ion-list lines="none">
      <ion-item>
        <ion-text>
          (내역서 발송 관련 설명 추가)
        </ion-text>
      </ion-item>
    </ion-list>

    <vp-button
      expand="full"
      :types="[{'fixed': fixed}]"
      @click="onClickDownload()">
      다운로드하기
    </vp-button>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { 
  IonList,
  IonLabel, 
  IonItem, 
  IonSelect, 
  IonSelectOption, 
  IonButton } from '@ionic/vue'
import { ref, onMounted } from 'vue'
import { useIonRouter } from '@ionic/vue'

import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import { getOrders } from '@/api/purchase/purchase'
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const ionRouter = useIonRouter()
const fixed = ref(true)
const searchParams = ref({
  year: undefined,
  month: undefined
})
const year = ref([])
const month = ref([])

const getDateList = () => {
  for(let i = 2023; i <= 2033; i+=1){
    year.value.push(i)
  }
  for(let i = 1; i <= 12; i+=1){
    month.value.push((i < 10) ? '0' + i : i)
  }
  searchParams.value.year = year.value[0]
  searchParams.value.month = month.value[0]
}

const pageInfo = ref({
  total: 0,
  pageNum: 1,
  pageSize: 99999,
  ordering: 'per_nm-'
})

// 엑셀다운로드
const onClickDownload = async() => {
  await exceldown()
}

async function exceldown(){
  const xlsx = require('xlsx')
  const wb = xlsx.utils.book_new()

  // sheet 생성 - aoa_to_sheet 방식
  const result = await getOrders()
  const exceHeader = {header: [['접수자이름', '접수자전화번호', '접수상태', '검수등급1', '검수등급1가격', '수거완료일자']]}
  
  const baseDt = ref(searchParams.value.year + '' + searchParams.value.month)
  const filterList = ref([])
  const tmpList = ref([])

  result.data.results.forEach((obj) => {
    if(!isEmpty(obj.take_date)){
      obj.tmp_take_date = String(obj.take_date).replace(/-/gi, '').substring(0,6)
    }
    return obj
  })

  filterList.value = result.data.results.filter((obj) => (obj.tmp_take_date === baseDt.value))

  const excelData = filterList.value.map(obj => {
    return Object.assign({}, '', {
      order_nm: obj.order_nm,
      order_tel_no: obj.order_tel_no,
      order_state_code: Number(obj.order_state_code) === 2 ? '수거' : Number(obj.order_state_code) === 3 ? '완료(입고)' : '접수',
      grade1: obj.grade1,
      grade1_price: obj.grade1_price,
      take_date: obj.take_date
    })
  })

  const ws = xlsx.utils.json_to_sheet(excelData)
  xlsx.utils.sheet_add_aoa(ws, exceHeader.header)

  // 엑셀 파일에 sheet set(엑셀파일, 시트데이터, 시트명)
  xlsx.utils.book_append_sheet(wb, ws, 'sheet1')
    
  // 엑셀 다운로드
  const title = dayjs().format('YYYYMMDDhhmmss')
  xlsx.writeFile(wb, title + '.xlsx')
}

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
onMounted(async () => {
  getDateList()
})
</script>
